import * as React from 'react';
import { Link } from 'gatsby';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import pirateGymnastics from '../../../images/educational-corner/blog28/gusarska-gimnastika.jpg';
import therapistAndKids from '../../../images/educational-corner/blog28/Logoped-radi-s-djecom.jpg';
import therapistExercise from '../../../images/educational-corner/blog28/Logoped-vjezba-izgovor-glasa-s-djetetom.jpg';

const SpeechDisorderTherapy = () => {
  return (
    <PageNew
      title="KOKOLINGO - Logopedska terapija"
      description="Logopedska terapija se izvodi uz pomoć logopeda; stručnjak koji se 
      bavi prevencijom, otkrivanjem, dijagnosticiranjem i tretmanom poremećaja ljudske 
      komunikacije, pod kojom se podrazumijevaju svi oni procesi i funkcije koji su 
      povezani s produkcijom govora te s percepcijom i produkcijom oralnoga i pisanoga 
      jezika, kao i oblicima neverbalne komunikacije."
      headline="Logoedpska terapija"
    >
      <Text as="h2">Tko je logoped?</Text>
      <Text as="p">
        Logoped je stručnjak koji se bavi prevencijom, otkrivanjem,
        dijagnosticiranjem i tretmanom poremećaja ljudske komunikacije, pod
        kojom se podrazumijevaju svi oni procesi i funkcije koji su povezani s{' '}
        <b>produkcijom govora</b> te s{' '}
        <b>percepcijom i produkcijom oralnoga i pisanoga jezika</b>, kao i
        oblicima <b>neverbalne komunikacije</b> (definicija Europskog udruženja
        logopeda – CPLOL).
      </Text>
      <Text as="p">
        <b>Logoped radi sa svim dobnim skupinama</b>: s malim bebama, s
        predškolskom i školskom djecom, adolescentima te odraslima.
      </Text>
      <BlogImage
        imgAlt="Logoped vjezba s djecom"
        imgTitle="Logoped vjezba s djecom"
        imgSrc={therapistAndKids}
      />
      <Text as="h2">
        S kojim teškoćama djeca i odrasli dolaze na logopedske terapije?
      </Text>
      <Text as="p">
        Logoped čini procjene i provodi terapijski rad s djecom i odraslima koji
        imaju:
      </Text>
      <Text as="p">
        <b>Teškoće s izgovorom glasova</b> - osoba određeni glas ili više
        glasova izgovara pogrešno (šator = <i>sator</i>), mijenja ga drugim
        glasom (šator = <i>tator</i>) ili ga izostavlja (šator = <i>ator</i>).
      </Text>
      <Text as="p">
        <ul>
          <li>
            artikulacijske teškoće, fonološke teškoće, dječja govorna apraksija,
            dizartrija
          </li>
        </ul>
      </Text>
      <Text as="p">
        <b>Poremećaj tečnosti govora</b> – osoba ima poremećen ritam i tempo
        govora. To znači da dolazi do ponavljanja glasova, slogova i riječi,
        umetanja, zastoja i produživanja glasova. Govor se slušaču može činiti
        presporim ili prebrzim.
      </Text>
      <Text as="p">
        <ul>
          <li>mucanje, brzopletost</li>
        </ul>
      </Text>
      <Text as="p">
        <b>Jezične teškoće</b> – osoba ima teškoće s razumijevanjem govornog
        jezika, njezina je rečenica agramatična (npr. On vole žuti lopta), ima
        suženi rječnik, teško prati verbalne upute, teško razumije pročitano.
      </Text>
      <Text as="p">
        <ul>
          <li>
            razvojni jezični poremećaj, oštećenje sluha, intelektualne teškoće,
            poremećaj iz spektra autizma, afazija (jezične teškoće kao
            posljedica pretrpljenog moždanog udara)
          </li>
        </ul>
      </Text>
      <Text as="p">
        <b>Komunikacijske teškoće</b> – mala djeca s komunikacijskim teškoćama
        vjerojatno će rjeđe ostvarivati kontakt očima, neće se sustavno
        odazivati na ime, teško će uspostaviti zajedničku igru s nekime. Malo
        starija djeca možda neće znati započeti razgovor sa svojim vršnjakom, a
        odrasli bi mogli imati vrlo ograničene teme za razgovor ili će stajati
        preblizu sugovornika, jer ne razumiju socijalna pravila.
      </Text>
      <Text as="p">
        <ul>
          <li>
            poremećaj iz spektra autizma, poremećaj socijalne komunikacije,
            pragmatički jezični poremećaji, selektivni mutizam, komunikacijski
            poremećaji nakon traumatskog oštećenja mozga
          </li>
        </ul>
      </Text>
      <Text as="p">
        <b>Teškoće čitanja, pisanja i računanja</b> – osoba može čitati netečno,
        imati teškoća s razumijevanjem pročitanog, imati nečitki rukopis,
        izostavljati slova u riječima, krivo prepisivati simbole u matematičkom
        zadatku,…
      </Text>
      <Text as="p">
        <ul>
          <li>disleksija, disgrafija, diskalkulija</li>
        </ul>
      </Text>
      <Text as="p">
        <b>Poremećaj glasa</b> – podrazumijeva odstupanje od njegovih normalnih
        obilježja visine, intenziteta i kvalitete pa glas može zvučati promukao,
        hrapav, nazalan, preglasan ili pretih. Problemi s glasom mogu biti
        organski uvjetovani, a mogu nastati i radi nepravilne upotrebe glasa
        (npr. česta nadvikivanja).
      </Text>
      <Text as="p">
        <b>Teškoće hranjenja (žvakanja i gutanja)</b> – mogu nastati zbog
        rascjepa nepca, preopuštene oralne muskulature, prevelike osjetljivosti
        organa potrebnih za žvakanje. Nalazimo ih kod osoba s cerebralnim
        oštećenjima (cerebralna paraliza, moždani udar), osoba s određenim
        genetskim sindromima (Downov sindrom) i osoba s poremećajem iz spektra
        autizma.
      </Text>
      <BlogImage
        imgAlt="Logoped vjezba s djetetom kako pravilno izgovoriti glas"
        imgTitle="Logoped vjezba s djetetom"
        imgSrc={therapistExercise}
      />
      <Text as="h2">
        Procjena – što očekivati od prvog susreta s logopedom?
      </Text>
      <Text as="p">
        Logopedska se procjena sastoji od razgovora s roditeljem iz kojeg
        logoped prikuplja podatke o:
        <ul>
          <li>
            trudnoći i porodu (rizična trudnoća, uranjen porod, niska porođajna
            masa, komplikacije pri porodu)
          </li>
          <li>
            ranom djetetovom razvoju (pojava prve riječi, prvih rečenica, dob u
            kojoj je prohodalo, teškoće s uvođenjem dohrane)
          </li>
          <li>
            povijesti komunikacijskih, jezičnih ili govornih teškoća u obitelji
          </li>
          <li>jezicima kojima se govori u obitelji</li>
          <li>povijesti bolesti povezanih s određenim logopedskim teškoćama</li>
        </ul>
      </Text>
      <Text as="p">
        Zatim će logoped ući u interakciju s djetetom i prikupljati informacije
        o djetetovom komunikacijskom, jezičnom i govornom razvoju kroz:
        <ul>
          <li>opservaciju spontane igre i razgovora s djetetom</li>
          <li>imenovanje i opisivanje slika,</li>
          <li>ponavljanje slogova i riječi,</li>
          <li>promatranje pokretljivosti govornih organa,</li>
          <li>standardizirane testove za procjenu izgovora i jezika</li>
        </ul>
      </Text>
      <Text as="p">
        Na kraju će logoped ponovno razgovarati s roditeljem i prenijeti mu
        svoje zaključke. Tada će se i dogovoriti o načinu i učestalosti
        provođenja terapija.
      </Text>
      <Text as="p">
        <b>
          Prije vašeg prvog susreta s logopedom, važno je djetetu opisati i reći
          što može očekivati, kako bi bilo što opuštenije.
        </b>
      </Text>
      <Text as="h2">Općenito o logopedskoj terapiji</Text>
      <Text as="p">
        <ul>
          <li>
            provode se u kabinetu u kojem možete očekivati radni stol, mjesto za
            igru na podu, ogledalo, aparat sa slušalicama, špatule i mnogo
            didaktičkih igara
          </li>
          <li>
            traju između 30 i 60 minuta, ovisno o dobi osobe i o vrsti i težini
            teškoća
          </li>
          <li>
            učestalost terapija je jednom do dva puta tjedno, no mogu u iznimnim
            slučajevima biti i svakodnevne
          </li>
          <li>
            logoped će u interakciji s djetetom, odnosno odraslom osobom,
            koristiti raznolike, zanimljive materijale: kartice, magnete,
            slikovnice, plastelin, tablet, laptop (
            <Link to="/" style={{ color: '#f7c104' }}>
              <b>kokolingo.hr</b>
            </Link>
            )
          </li>
          <li>
            logoped može od klijenta tražiti imitaciju pokreta jezika i usnica,
            ponavljanje slogova, riječi i rečenica; može poticati proizvodnju
            gesti ili pokazivanje određenog simbola u svrhu komunikacije (ako
            dijete još nije spremno za govorno izražavanje); može poticati
            razvoj fine motorike šake (crtanje, grafomotoričke vježbe, igra
            plastelinom)
          </li>
        </ul>
      </Text>
      <Text as="p">
        <b>
          Izrazito je važno prema uputama logopeda provoditi vježbe kod kuće
        </b>
        , naročito ako osoba logopedu dolazi jednom tjedno ili rjeđe.
      </Text>
      <BlogImage
        imgAlt="Pravilan oblik ustiju kod izgovora pomaze pri pravilnom izgovoru glasova"
        imgTitle="Gusarska gimnastika"
        imgSrc={pirateGymnastics}
      />
      <Text as="h2">Koliko je potrebno odlaziti logopedu?</Text>
      <Text as="p">
        Vremensko razdoblje odlazaka na logopedske terapije ovisi o:
        <ul>
          <li>dobi</li>
          <li>vrsti i težini teškoća</li>
          <li>učestalosti odlazaka na logopedske terapije</li>
          <li>zdravstvenom stanju, odnosno postojanju organskog oštećenja</li>
        </ul>
      </Text>
      <Text as="h2">Kada se javiti logopedu?</Text>
      <Text as="p">
        Kao što je prije navedeno, logoped je stručnjak čije područje rada nije
        samo govor, već i <b>komunikacija</b> (
        <i>sposobnost da se svjesno šalje i prima poruka sa značenjem</i>
        ). To znači da{' '}
        <b>
          nije potrebno pričekati s javljanjem logopedu sve dok dijete ne
          progovori
        </b>
        . Govor je vrlo kompleksna radnja i potrebno je da se prije njegove
        pojave usvoji niz predvještina (kontakt očima, imitacija, jezično
        razumijevanje,...). Te se predvještine mogu poticati, kako u
        individualnom logopedskom radu, tako i u svakodnevnoj igri s roditeljima
        i užim članovima obitelji, uz logopedsko savjetovanje.
      </Text>
      <Text as="p">Ema Tadić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            <a
              href="https://www.asha.org/public/who-are-speech-language-pathologists/"
              target="_blank"
              hrefLang="hr"
              rel="noopener noreferrer"
            >
              <b>
                https://www.asha.org/public/who-are-speech-language-pathologists/
              </b>
            </a>
          </li>
          <li>
            <a
              href="https://www.healthline.com/health/speech-therapy#what-to-expect"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>
                https://www.healthline.com/health/speech-therapy#what-to-expect
              </b>
            </a>
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default SpeechDisorderTherapy;
